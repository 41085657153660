import { consola } from "consola";
import { useGenerateUrl } from "../../../utils/url.utils";
import { useParseData } from "../../../utils/parse.utils";
import { TypeNewsCategoryDto, TypeNewsDto } from "./news.type";
export const useNewsUtils = (globalSettings: any) => {

    const { replaceFileUrl } = useParseData();
    const { generateUrlByNameRoute } = useGenerateUrl(globalSettings);
    const generateNewsDto = (item: any): TypeNewsDto => {

        try {
            const output = {
                ...item,
                translations: {
                    title: item.title,
                    description: item.description
                },
                content: item.content ? replaceFileUrl(item.content) : null,
                url: generateUrlByNameRoute("news_detail", {
                    id: item.id,
                    slug: item.slug
                })
            };

            if (item.category) {
                output["category"] = typeof item.category === "object" ? generateNewsCategoryDto(item.category) : item.category;
            }

            if (item.related_news && Array.isArray(item.related_news) && item.related_news[0]) {
                output["related_news"] = item.related_news.map((ite: any) => generateNewsDto(ite.related_news_id));

            }

            return output;

        } catch (e) {
            consola.error("Error: generateNewsDto!");
            consola.error(e);
            return {};
        }
    }

    const generateNewsCategoryDto = (item: any): TypeNewsCategoryDto => {
        try {
            const output = {
                ...item,
                // url: generateUrlByNameRoute("news_category", {
                //     id: item.id,
                //     slug: item.slug
                // })
            };

            return output;
        } catch (e) {
            consola.error("Error: generateNewsCategoryDto!");
            consola.error(e);
            return {};
        }
    }

    return {
        generateNewsDto,
        generateNewsCategoryDto,
    }
}
